import React from 'react';

function UrlList({ urls }) {
  return (
    <div className="url-list">
    <h2 className="url-list-title">List of URLs</h2>
    <ul className="url-list-items">
      {urls.map(({ path, url }, index) => (
        <li key={index} className="url-list-item">
          <strong>Path:</strong> <code className="url-path">/{path}</code> <br />
          <strong>URL:</strong> <a href={`/${path}`} className="url-link">{url}</a>
        </li>
      ))}
    </ul>
  </div>
  );
}

export default UrlList;